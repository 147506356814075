<template>
<div>
  <div class="title">
      {{ title }}
    </div>
    <a-table
    bordered
    :data-source="item.list"
    :pagination="false"
    rowKey="id"
    :row-selection="{
      selectedRowKeys,
      onChange: onSelectChange,
    }"
  >
    <a-table-column title="附件名称" data-index="name" align="center"/>
    <a-table-column title="操作" align="center" width="50px">
      <template slot-scope="text">
        <a
          @click="download(text)" 
          >下载</a
        >
      </template>
    </a-table-column>
  </a-table>
</div>
</template>
<script>
import { downloadSingle } from '@/api/file'
export default {
  props:{
    item: {// 包括type及值
      type: Object,
      default: ()=>({})
    },
    selected: {
      type : Array,
      default: ()=>[]
    }
  },
  watch:{
    selected(newVal){
      if(!newVal.length){
        this.selectedRowKeys = [];
        this.selectRowsId = [];
      }
    }
  },
  data () {
    return {
      selectedRowKeys: [],
      selectRowsId: [],
    }
  },
  computed: {
    title() {
      if(this.item.name === '商务文件' || this.item.name === '技术文件'){ // 如果是商务或技术文件，特殊处理
        return `投标文件(${this.item.name})`;
      }
      return this.item.name;
    }
  },
  methods:{
    onSelectChange(keys,text) {
      this.selectRowsId = []
      this.selectedRowKeys = keys;
      for(let item of text){
        this.selectRowsId.push(item.id)
      }
      console.log(this.selectRowsId)
      this.$emit('getSelectedId',{
        name: this.item.name,
        idList: this.selectRowsId
      })
    },
    download(text){
      console.log('download',text)
      downloadSingle(text.id).then(res=>{
        window.open(res.completePath, '_blank');       
      })

    },
  }
}
</script>
<style lang="less" scoped>
.title{
  margin-top: 16px;
  margin-bottom: 16px;
}
</style>
