<template>
  <div id="evaluation"> 
    <Pane/>
    <div class="container">
      <div id="printContent">
        <div class="header">
          <div class="title">评标意见表</div>
        </div>

        <div class="row">
          <div class="project-name">
            <span> 项目名称：</span>
            <span> {{detail.projectName}} </span>
          </div>

          <div class="extra not-print-content">
            <span @click="exportFile"><a-icon type="table" style="margin-right:5px;color:#1890ff;"/>导出表格</span>
            <span v-print="printObj"><a-icon type="printer" style="margin-right:5px;color:#564fc1;"/>打印页面</span>
          </div>
        </div>

        <div class='description'>
          <div class='label'>标段名称</div>
          <div class='value'>{{detail.name}}</div>
          <div class='label'>开标地点</div>
          <div class='value'>{{detail.bidAddress}}</div>
          <div class='label'>招标方式</div>
          <div class='value1'>
            <DataDictFinder
            dictType="bid_method"
            :dictValue="detail.bidMethod"
              />
          </div>
          <div class='label'>开标日期</div>
          <div class='value2'>{{detail.bidDate}}</div>
        </div>

        <div class='list'>
          <div class='item'>
            <div style='width:44px; flex: none'>序号</div>
            <div style='flex: 3'>
              <div>
                报名单位
              </div>
            </div>

            <div class="price">
              <div>报价/万元</div> 
            </div>

            <div class="price">
              <div>二次报价/万元(若有)</div>
            </div>

            <div class="switch">
              <div>付款方式是否响应</div>
            </div>

            <div class="switch">
              <div>资信承诺是否符合</div>
            </div>

            <div class="switch">
              <div>供货范围是否符合</div>
            </div>

            <div class="switch">
              <div>商务/技术要求是否符合</div>
            </div>

            <div style='flex: 2'>
              <div style="width:100px;">
                投标单位答疑回复(如有)
              </div>
            </div>
            <div style='flex: 2'>
              <div style="width:100px;">
                其他意见
              </div>
            </div>
          </div>

          <div v-for="(item, index) in formList" :key="item.id">
            <EvaluationItem :msg="item" :index="index" @getForm="getForm"/>
          </div>
        </div>

        <div class='textarea'>
          评标意见：
          <textarea placeholder='请输入' v-model="juryRemarks"/>
        </div>
      </div>

      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">取消</a-button>
          <a-button type="primary" 
            @click="submit" 
            :loading="saveLoading"
          >提交</a-button>
        </a-space>
      </div>
    </div>
  </div>
</template>

<script>
import EvaluationItem from './components/evaluation-item.vue'

import { fetchDetail } from "@/api/bidding";
import { juryEvaluation, getJuryDetail, downloadJury } from "@/api/bidding/evaluation";

import { saveAs } from "file-saver";

export default {
  components:{
    EvaluationItem
  },
  data() {
    return {
      detail: {},
      formList: [],
      
      id: "", // 标段id
      registerFile: {},
      juryId:'', // 评委的id
      recordList: [], // 评标记录
      juryRemarks: "", //评标意见
      isView: false,

      printObj:{
        id: "printContent",
      },

      saveLoading: false,
    };
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};
    this.id = id;

    if (id) {
      this.getDetail(id);// 获取标段的详情
      this.getEvaluation(id); // 从评委评标详情取该评委评标的数据
    }
  },

  methods: {
    getDetail(id) {
      fetchDetail({
        id,
      }).then((res) => {
        this.detail = res;
        console.log(this.detail)
      });
    },
    getEvaluation(id){
      getJuryDetail(id).then((res) => {
        console.log(res)
        // 获取评标信息
        this.formList = res.recordList;
        this.juryRemarks = res.juryRemarks;
        this.juryId = res.id;

        this.setFileList(res.registerList);// 添加报名单位的文件
        console.log(this.formList)
      });
    },
    setFileList(registerList){
      this.formList.forEach(item=> {
        let register = registerList.find(ele=> ele.userId === item.userId)
        if(!register){
          item.fileList = [];
        }else{
          item.fileList = [
          {
            name: '商务文件',
            list: register?.filesBusinessList || []
          },
          {
            name: '技术文件',
            list: register?.filesTechList || []
          },
          {
            name: '投标保证金缴纳凭证',
            list: register?.filesBidBondCertList || []
          },
          {
            name: '标书购买凭证',
            list: register?.filesBidBuyCertList || []
          },
          {
            name: '其他文件',
            list: register?.filesOtherList || []
          }
        ]
        }
      })
    },

    getForm(value){
      // 如有，替换，没有，添加
      let index = this.recordList.findIndex(item=>{
        return item.id == value.id
      })
      
      if(index > -1){
        this.recordList.forEach(item=>{
         if(item.id == value.id){
          item.formData = value.formData
         }
        })
      }else{
        this.recordList.push(value)
      }

      console.log('所有选择的',this.recordList)
    },

    submit() {
      let parmas = {
        id: this.juryId,
        juryRemarks: this.juryRemarks,
        recordList: this.recordList.map(item=>item.formData),
      }

      console.log(parmas);
      
      juryEvaluation(parmas).then(() => {
        this.getEvaluation(this.id);
      });

    },

    exportFile(){
      downloadJury({
        itemId: this.id,
        juryId: this.juryId
      })
      .then((blob) => {
        saveAs(blob, "评标意见表.doc");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 48px 110px;
}

.header {
  .title {
    font-weight: bold;
    text-align: center;
    font-size: 22px;
    padding: 12px 0
  }
}

.row{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  .project-name {
    font-weight: bold;
    font-size: 16px;

    & > span:last-child {
      color: #1890ff;
    }
  }
  .extra{
    span:first-child{
      margin-right: 20px;
    }
    span{
      cursor: pointer;
    }
  }
}

.description {
  border: 1px solid #e8e8e8;
  border-bottom-width: 0;
  border-right-width: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  .label {
    background: #fafafa;
    padding: 12px;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    text-align:center;
  }
  .value {
    padding: 12px;
    grid-column-start: 2;
    grid-column-end: 5;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    font-weight: bold;
  }
  .value1 {
    padding: 12px;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    font-weight: bold;

  }
  .value2 {
    padding: 12px;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    font-weight: bold;

  }
}

.list {
  border: 1px solid #e8e8e8;
  border-top-width: 0;
  border-bottom-width: 0;
  border-right-width: 0;
  .item {
    display: flex;
    & > div {
      flex: 1;
      padding: 12px 0;
      border-bottom: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: bold;
    }
    .price{
      div{
        width: 70px;
      }
    }
    .switch{
      div{
        width: 44px;
      }
    }
  }
  .item:first-child {
    & > div {
      background: #fafafa;
    }
  }
}

 /*  input{
    outline: none;
    border: none;
  } */

.textarea {
  border: 1px solid #e8e8e8;
  border-top-width: 0;
  display: flex;
  align-items: baseline;
  padding: 12px;
  textarea {
    border-width: 0;
    outline: none;
    resize: none;
    flex: 1;
    height: 120px;
  }
}

.center {
  margin-top: 80px;
}
</style>

