<template>
  <div>
    <div @click="show">
      <slot />
    </div>

    <a-modal
      :title="title"
      :visible="visible"
      @cancel="cancel"
      :footer="null"
      width="400px"
      dialogClass="modal-container"
    >
      <div class="container">
        <div v-if="msg.length !== 0">
          <div class="list" v-for="(item, index) in msg" :key="index">
            <FileTableItem v-if="item.list.length"
              :item="item"
              @getSelectedId="downloadSelected"
              :selected="selectedFile"
            />
          </div>
        </div>
        <div v-else>
          暂无数据
        </div>
        
        <div class="center">
          <a-space>
            <a-button @click="cancel">关闭</a-button>
            <a-button type="primary" @click="submit">下载</a-button>
          </a-space>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import FileTableItem from "./file-table-item";
import { flatten } from "@/utils/flattenArr";
import { downloadBatch } from '@/api/file'
export default {
  components: {
    FileTableItem,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    msg: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      id: "", // itemId 标段id
      /* fileList: [ //msg 格式
        {
          name: "投标文件",
          list: [
            {
              id: 1,
              name: "文件名1",
              completePath: "这是一个路径1",
            },
            {
              id: 2,
              name: "文件名2",
              completePath: "这是一个路径2",
            },
          ],
        },
        {
          name: "投标保证金缴纳凭证",
          list: [
            {
              id: 3,
              name: "文件名3",
              completePath: "这是一个路径3",
            },
            {
              id: 4,
              name: "文件名4",
              completePath: "这是一个路径4",
            },
          ],
        },
        {
          name: "标书购买凭证",
          list: [
            {
              id: 5,
              name: "文件名5",
              completePath: "这是一个路径5",
            },
            {
              id: 6,
              name: "文件名6",
              completePath: "这是一个路径6",
            },
          ],
        },
        {
          name: "其他文件",
          list: [
            {
              id: 7,
              name: "文件名7",
              completePath: "这是一个路径7",
            },
            {
              id: 8,
              name: "文件名8",
              completePath: "这是一个路径8",
            },
          ],
        },
      ], */
      selectedFile: [], // 所有选中的项
    };
  },
  methods: {
    show() {
      if (!this.disabled) {
        this.visible = true;
      }
    },
    cancel() {
      this.visible = false;
      this.selectedFile = [];
    },
    downloadSelected(value) {
      // 如有，替换，没有，添加
      let index = this.selectedFile.findIndex((item) => {
        return item.name == value.name;
      });

      if (index > -1) {
        this.selectedFile.forEach((item) => {
          if (item.name == value.name) {
            item.idList = value.idList;
          }
        });
      } else {
        this.selectedFile.push(value);
      }

      console.log("所有选择的", this.selectedFile);
    },
    submit() {
      // 取出所有的idList
      let temp = this.selectedFile.map((item) => {
        return item.idList;
      });

      let param = flatten(temp).join(",");
      console.log(param);
      if (!param) {
        this.$message.error("请选择文件！");
        return;
      }
      downloadBatch(param).then( res => {
        for(let item of res){
          window.open(item.completePath,'_blank');
        }
      }
      )
    },
  },
};
</script>
<style lang="less" scoped>
.center {
  margin-top: 20px;
}

</style>
<style lang="less">
.modal-container{
  .ant-empty-normal{
    margin: 0 auto;
  }
}
</style>
