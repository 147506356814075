import request from '../request'

// 批量下载文件，返回值包括（code，data,message)
export function downloadBatch(params) {
  return request({
      url: `/common-service/file/queryList/resp/${params}`,
  })
}

// 下载单个文件
export function downloadSingle(params) {
  return request({
      url: `/common-service/file/query/${params}`,
  })
}
