<template>
  <div id="evaluation-item">
    <div style='width:44px; flex: none'>{{index+1}}</div>
    <div style="color: #1890ff;flex: 3">
        <FileTableDialog
          title="报名文件"
          :msg="msg.fileList"
          >
          <span class="user">
            {{msg.user}}
          </span>
        </FileTableDialog>
    </div>
    <div >
      <!-- 报价 -->
      <a-input-number placeholder='请输入' v-model="form.quoteFirst" @change="handleChange"/>
    </div>
    <!-- 二次报价 -->
    <div>
      <a-input-number placeholder='请输入' v-model="form.quoteSec" @change="handleChange"/>
    </div>
    <!-- 付款方式是否响应 -->
    <div>
      <span class="print-content-line">
        <span v-if="form.isPaymentResponse">是</span>
        <span v-else>否</span>
      </span>
      <a-switch class="not-print-content" checked-children="开" un-checked-children="关" v-model="form.isPaymentResponse" @change="handleChange"/>
    </div>
    <!-- 资信承诺是否符合 -->
    <div>
      <span class="print-content-line">
        <span v-if="form.isQualification">是</span>
        <span v-else>否</span>
      </span>
      <a-switch class="not-print-content" checked-children="开" un-checked-children="关" v-model="form.isQualification" @change="handleChange"/>
    </div>
    <!-- 供货范围是否符合 -->
    <div>
      <span class="print-content-line">
        <span v-if="form.isSupplyScope">是</span>
        <span v-else>否</span>
      </span>
      <a-switch class="not-print-content" checked-children="开" un-checked-children="关" v-model="form.isSupplyScope" @change="handleChange"/>
    </div>
    <!-- 商务/技术要求是否符合 -->
    <div>
      <span class="print-content-line">
        <span v-if="form.isTecBusiness">是</span>
        <span v-else>否</span>
      </span>
      <a-switch class="not-print-content" checked-children="开" un-checked-children="关" v-model="form.isTecBusiness" @change="handleChange"/>
    </div>
    <div style='flex: 2'>
      <a-textarea placeholder='请输入' 
      style="" 
      :auto-size="{ minRows: 1 }" 
      v-model="form.responseQuestion" 
      @input="handleChange"/>
    </div>
    <div style='flex: 2'>
      <a-textarea placeholder='请输入' 
      style="border: none;text-align: center;" 
      :auto-size="{ minRows: 1 }" 
      v-model="form.juryOtherRemarks" 
      @input="handleChange"/>
    </div>
  </div>
</template>
<script>
import FileTableDialog from '@/views/bidding/components/file-table-dialog'
export default {
  components:{
    FileTableDialog
  },
  props:{
    msg:{
      type: Object,
      default: ()=>({})
    },
    index:{
      type: Number,
      default:0
    }
  },
 
  data () {
    return {
      form: { //评标意见表 msg来初始化
        id: this.msg.id || '',
        userId: this.msg.userId || '',
        quoteFirst: this.msg.quoteFirst || null,
        quoteSec: this.msg.quoteSec || null,
        isPaymentResponse: this.msg.isPaymentResponse|| false,
        isQualification: this.msg.isQualification|| false,
        isSupplyScope: this.msg.isSupplyScope|| false,
        isTecBusiness: this.msg.isTecBusiness|| false,  

        responseQuestion: this.msg.responseQuestion|| "",//投标单位答疑回复
        juryOtherRemarks: this.msg.juryOtherRemarks|| "",//其他意见
      },
    }
  },
  mounted(){
    console.log(this.form)
    console.log(this.msg)
    
  },
  methods:{
    handleChange(){
      console.log(this.form) // 当前的form
      this.$emit('getForm',{
        id: this.msg.id,
        formData: this.form,
      })
    }
  }
}
</script>
<style lang="less" scoped>
#evaluation-item {
  display: flex;
  & > div {
    flex: 1;
    padding: 12px 0;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
      display: flex;
      flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  input{
    outline: none;
    border: none;
    text-align: center;
    width: 100px;
  }
  textarea{
    border: none;
    text-align: center;
    width: 100px;
  }
  .user{
    cursor: pointer;
  }
  .print-content-line{
    display: none;
  }
}
#evaluation-item :first-child {
  & > div {
    background: #fff;
  }
}
</style>

<style lang="less">
#evaluation-item{
  .ant-input-number{
    border: none;
    width: 70px;
    .ant-input-number-input{
      text-align: center;
    }
  }
}
</style>