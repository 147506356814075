import request from '../request'

export function fetchList(params) {
    return request({
        url: '/market-service/bid/item/list',
        params
    })
}


export function fetchDetail(params) {
    return request({
        url: '/market-service/bid/item/query/' + params.id,
    })
}

export function add(data) {
    return request({
        url: '/market-service/bid/item/add',
        method: 'post',
        data
    })
}

export function edit(data) {
    return request({
        url: '/market-service/bid/item/update',
        method: 'post',
        data
    })
}

export function cancel(data) {
    return request({
        url: '/market-service/bid/item/cancel/' + data.id,
        method: 'post',
    })
}

// 中标通知发送
export function sendWin(data) {
    return request({
        url: '/market-service/bid/item/win',
        method: 'post',
        data
    })
}

