import request from '../request'
import download from '../download'

// 选评委
export function selectUser(data) {
  return request({
      url: '/market-service/bid/jury/select',
      method: 'post',
      data,
  })
}

// 删除评委
export function removeUser(data) {
  return request({
      url: `/market-service/bid/jury/del/${data.itemId}/${data.juryId}`,
      method: 'post',
  })
}

// 评委评价
export function juryEvaluation(data) {
  return request({
      url: '/market-service/bid/jury/evaluation',
      method: 'post',
      data,
  })
}

// 评委个人信息
export function getJuryDetail(data) {
  return request({
      url: `/market-service/bid/jury/my/jury/${data}`,
      data,
  })
}

// 评委列表-包括评审信息-报名信息
export function getJuryList(data) {
  return request({
      url: `/market-service/bid/jury/jury/${data}`,
      data,
  })
}

// 评委汇总
export function jurySummary(data) {
  return request({
      url: '/market-service/bid/jury/summary',
      method: 'post',
      data,
  })
}

// 导出汇总意见
export function downloadSummary(data) {
  return download({
      url: `/market-service/bid/jury/jurySummaryFile/${data}`, 
  })
}

// 导出评委意见
export function downloadJury(data) {
  return download({
      url: `/market-service/bid/jury/juryRecordFile/${data.itemId}/${data.juryId}`, 
  })
}